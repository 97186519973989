<template>
  <b-card>
    <g-form @click="print">
      <b-row>
        <!-- from date  -->
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.fromDate"
            label-text="fromDate"
          />
        </b-col>

        <!-- to date  -->
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.toDate"
            label-text="toDate"
          />
        </b-col>

        <b-col
          v-if="type.route === 'studentsAccountStatement-report'"
          md="4"
        >
          <label
            class="dateLabel font-small-4"
            for="registrationDateFrom"
          > {{ $t('registrationDateFrom') }}
          </label>
          <b-form-datepicker
            id="registrationDateFrom"
            v-model="selectedItem.registrationFromDate"
            locale="ar"
            :date-format-options=" {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            class="mb-2"
            name="registrationFromDate"
          />
        </b-col>

        <b-col
          v-if="type.route === 'studentsAccountStatement-report'"
          md="4"
        >
          <label
            class="dateLabel font-small-4"
            for="registrationDateTo"
          > {{ $t('registrationDateTo') }}
          </label>
          <b-form-datepicker
            id="registrationDateTo"
            v-model="selectedItem.registrationToDate"
            locale="ar"
            :date-format-options=" {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            class="mb-2"
            name="registrationDateTo"
          />
        </b-col>

        <!-- student  -->
        <b-col md="4">
          <student-autocomplete
            ref="autoComlete"
            name="student"
            :value.sync="selectedItem.studentId"
            url="students/getStudentsTaxLookup"
            @change:action="(val) => students.push(val)"
          />
        </b-col>

        <!-- stage  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.stageId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="stage"
              field="select"
              name="stage"
              :options="stages"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- row  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.rowId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="row"
              field="select"
              name="row"
              :options="rows"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- classroom -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.classroomId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="class"
              field="select"
              name="classroom"
              :options="classrooms"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- guardian -->
        <b-col
          v-if="type.route === 'studentsAccountStatement-report'"
          md="4"
        >
          <b-form-group>
            <g-field
              :value.sync="selectedItem.guardianId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="guardian"
              field="select"
              name="fromGuardian"
              :options="guardians"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- tax Status  -->
        <b-col
          v-if="type.route !== 'studentsAccountStatement-report'"
          md="4"
        >
          <b-form-group>
            <g-field
              :value.sync="helper.taxableStatus"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="voucherSituationFromTax"
              field="select"
              name="taxStatusId"
              :options="taxesStatus"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- users -->
        <b-col
          v-if="type.route === 'collectionVouchers-report'"
          md="4"
        >
          <b-form-group>
            <g-field
              :value.sync="selectedItem.userId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="user"
              field="select"
              name="user"
              :options="users"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- payment method -->
        <b-col
          v-if="type.route === 'collectionVouchers-report'"
          md="4"
        >
          <b-form-group>
            <g-field
              :value.sync="helper.paymentMethod"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="Payment Method"
              field="select"
              name="paymentMethod"
              :options="paymentWays"
              label="arabicName"
              @change="() => {
                selectedItem.paymentId = null;
                helper.otherPaymentType = null;
              }"
            />
          </b-form-group>
        </b-col>

        <!-- safe payment  -->
        <b-col
          v-if="helper.paymentMethod == 'cash'"
          md="4"
        >
          <b-form-group>
            <g-field
              field="select"
              label="arabicName"
              name="safePayment"
              label-text="safeName"
              :options="safes"
              :dir="isRight ? 'rtl' : 'ltr'"
              :value.sync="selectedItem.paymentId"
            />
          </b-form-group>
        </b-col>

        <!-- other payment  -->
        <b-col
          v-if="helper.paymentMethod == 'other'"
          md="4"
        >
          <b-form-group>
            <g-field
              :value.sync="helper.otherPaymentType"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="PaymentMethods"
              field="select"
              name="PaymentMethods"
              :options="payments"
              label="arabicName"
            />
          </b-form-group>
        </b-col>

        <!-- bank method  -->
        <b-col
          v-if="helper.paymentMethod == 'other' && helper.otherPaymentType"
          md="4"
        >
          <b-form-group>
            <g-field
              :value.sync="selectedItem.paymentId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="bankName"
              field="select"
              name="bank"
              disabled
              :options="banks"
              label="arabicName"
            />
          </b-form-group>
        </b-col>

        <!-- previous Balance Case -->
        <b-col
          v-if="type.route === 'studentsAccountStatement-report'"
          class="gb-package prev-balance"
          md="8"
        >
          <label> {{ $t("balanceCase") }} </label>
          <b-button-group>
            <b-button
              :class="['gb-btn-child', {btnActive: selectedGroupName === 'all'}]"
              variant="flat-primary"
              @click="selectedGroupName = 'all'"
            >
              {{ $t("all") }}
            </b-button>
            <b-button
              :class="['gb-btn-child', {btnActive: selectedGroupName === 'greaterThan'}]"
              variant="flat-primary"
              @click="selectedGroupName = 'greaterThan'"
            >
              {{ $t("greaterThan") }}
            </b-button>

            <b-button
              :class="['gb-btn-child', {btnActive: selectedGroupName === 'lessThan'}]"
              variant="flat-primary"
              @click="selectedGroupName = 'lessThan'"
            >
              {{ $t("lessThan") }}
            </b-button>

            <b-button
              :class="['gb-btn-child', {btnActive: selectedGroupName === 'equal'}]"
              variant="flat-primary"
              @click="selectedGroupName = 'equal'"
            >
              {{ $t("equal") }}
            </b-button>
          </b-button-group>
        </b-col>

        <b-col
          v-if="selectedGroupName !== 'all'"
          md="4"
        >
          <g-field
            :value.sync="selectedItem.balanceValue"
            label-text="balanceValue"
            name="balanceValue"
            type="number"
          />
        </b-col>

        <b-col
          v-if="type.route === 'studentsAccountStatement-report'"
          md="4"
        >
          <label
            style="font-size: 14px; margin-bottom: 7px"
          >
            {{ $t("includeSuspendedStudents") }}
          </label>
          <b-form-group>
            <b-form-checkbox
              v-model="helper.includeSuspended"
              class="custom-control-primary"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="type.route === 'studentsAccountStatement-report'"
          md="4"
        >
          <label
            style="font-size: 14px; margin-bottom: 7px"
          >
            {{ $t("suspendedStudentsOnly") }}
          </label>
          <b-form-group>
            <b-form-checkbox
              v-model="helper.suspendedOnly"
              class="custom-control-primary"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-if="type.route !== 'studentsAccountStatement-report'"
            class="mx-25"
            :variant="'outline-primary'"
            @click="print('generalTotal')"
          >
            <feather-icon
              class="mr-25"
              icon="ColumnsIcon"
            />
            {{ $t("printGeneralTotal") }}
          </b-button>
          <b-button
            class="mx-25"
            :variant="'relief-primary'"
            @click="print('total')"
          >
            <feather-icon
              class="mr-25"
              icon="ListIcon"
            />
            {{ $t("printTotal") }}
          </b-button>
          <b-button
            class="mx-25"
            :variant="'outline-primary'"
            @click="print('details')"
          >
            <feather-icon
              class="mr-25"
              icon="LoaderIcon"
            />
            {{ $t("printDetails") }}
          </b-button>

          <b-button
            v-if="type.route === 'studentsAccountStatement-report' && currentBranch.isTaxCollectionActivated"
            class="ml-25"
            :variant="'relief-primary'"
            @click="print('deservedFees')"
          >
            <feather-icon
              class="mr-25"
              icon="ShoppingBagIcon"
            />
            {{ $t("printDerservedFees") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import reportMixin from '@/mixin/reportMixin';
import { reportParameters, taxesStatus, paymentWays } from '@/libs/acl/Lookups'
import StudentAutocomplete from '@/components/StudentAutoComplete.vue';

export default {
  components: {
    StudentAutocomplete,
  },
  mixins: [reportMixin],
  props: {
    type: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      students: [],
      stages: [],
      rows: [],
      classrooms: [],
      guardians: [],
      taxesStatus: taxesStatus,
      selectedItem: {
        studentId: null,
        stageId: null,
        rowId: null,
        classroomId: null,
        guardianId: null,
        balanceCase: null,
        balanceValue: 0,
        userId: null,
        paymentMethodId: null,
        paymentId: null,
        isSuspended: false,
        fromDate: null,
        toDate: null,
        payMethodId: null,
        registrationFromDate: null,
        registrationToDate: null
      },
      selectedGroupName: 'all',
      users: [],
      safes: [],
      banks: [],
      paymentWays: paymentWays,
      helper: {
        otherPaymentType: null,
        paymentMethod: null,
        includeSuspended: false,
        suspendedOnly: false,
        taxableStatus: null
      }
    };
  },
  watch: {
    selectedGroupName(val) {
      if (val === 'all') {
        this.selectedItem.balanceValue = 0
      }
    },

    'type.route'(routeName) {
      if (routeName !== 'collectionVouchers-report') {
        this.selectedItem.userId = null;
        this.helper.paymentMethod = null;
        this.selectedItem.paymentId = null;
      }
    },

    'helper.otherPaymentType'(newVal) {
      this.selectedItem.paymentId = newVal
                                  ? this.payments.find((v) => v.id === newVal).bankId
                                  : null;
      this.selectedItem.payMethodId = newVal
                                  ? this.payments.find((v) => v.id === newVal).id
                                  : null;
                                },
    'selectedItem.studentId'(studentId) {
      this.get({ url: 'Students', id: studentId }).then((data) => {
          this.selectedItem.stageId = data.stageId
          this.selectedItem.rowId = data.row
          this.selectedItem.classroomId = data.classroomId
          this.selectedItem.guardianId = data.guardianId
        })
    },

    'helper.includeSuspended'(isActive) {
      if (isActive && this.helper.suspendedOnly) this.helper.suspendedOnly = false;
    },

    'helper.suspendedOnly'(isActive) {
      if (isActive && this.helper.includeSuspended) this.helper.includeSuspended = false;
    },

    'selectedItem.registrationFromDate'(newVal) {
      if (newVal > this.selectedItem.registrationToDate) {
        this.doneAlert({ title: this.$t('startDateShouldBelessThanEndDate'), type: 'error' });
        return false
      }
      return true
    },

    'selectedItem.registrationToDate'(newVal) {
      if (this.selectedItem.registrationFromDate > newVal) {
        this.doneAlert({ title: this.$t('startDateShouldBelessThanEndDate'), type: 'error' });
        return false
      }
      return true
    }
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.selectedItem.fromDate = this.getDate(this.fiscalYearStart)
    this.selectedItem.toDate = this.getDate(this.fiscalYearEnd)
    this.getData();
    if (this.type.route === 'collectionVouchers-report') {
      this.getBanks();
    }
  },

  methods: {
    checkDate() {
      if (this.selectedItem.fromDate === null) {
        this.selectedItem.fromDate = this.getDate(this.fiscalYearStart);
      }
      if (this.selectedItem.toDate === null) {
        this.selectedItem.toDate = this.getDate(this.fiscalYearEnd);
      }
    },
    getBanks() {
      this.get({ url: 'Safes' }).then((data) => {
        this.safes = data;
      });
      this.get({ url: 'Banks' }).then((data) => {
        this.banks = data;
      });
      this.get({ url: 'PaymentMethods' }).then((data) => {
        this.payments = data;
      });
    },

    getData() {
        this.get({ url: 'rows' }).then((data) => {
          this.rows = data;
        })
        .then(() => {
          this.get({ url: 'stages' }).then((data) => {
            this.stages = data;
          });
        })
        .then(() => {
          this.get({ url: 'classrooms' }).then((data) => {
            this.classrooms = data;
          });
        })
        .then(() => {
          this.get({ url: 'guardians' }).then((data) => {
            this.guardians = data;
          });
        })
        .then(() => {
          this.get({ url: 'users' }).then((data) => {
            this.users = data;
        })
        })
    },

    getReportNameBasedOnType(type) {
      let repName = '';
      this.selectedItem.voucherType = ''

      // for financial voucher
      if (this.type.route === 'collectionVouchers-report' || this.type.route === 'paymentVouchers-report') {
        this.selectedItem.voucherType = this.type.route === 'collectionVouchers-report' ? 1 : 2;
        switch (type) {
          case 'total':
            repName = this.isRight ? 'financialVouchersSummary-ar' : 'financialVouchersSummary-en'
            break;

          case 'details':
            repName = this.isRight ? 'financialVouchers-ar' : 'financialVouchers-en'
            break;

          case 'generalTotal':
            repName = this.isRight ? 'financialVouchersGeneralSummary-ar' : 'financialVouchersGeneralSummary-en'
            break;

          default:
            repName = ''
            break;
        }

      // for student account
      } else if (this.type.route === 'studentsAccountStatement-report') {
        switch (type) {
          case 'total':
            repName = this.isRight ? 'StudentsAccountStatementSummary-ar' : 'StudentsAccountStatementSummary-en'
            break;

          case 'details':
            repName = this.isRight ? 'StudentsAccountStatement-ar' : 'StudentsAccountStatement-en'
            break;

          case 'deservedFees':
            repName = this.isRight ? 'DeservedFeesAccountStatement-ar' : 'DeservedFeesAccountStatement-en'
            break;

          default:
            repName = ''
            break;
        }
      }

      return repName
    },

    filterDataBaseOnId(source, compareTo) {
      return source.find((val) => val.id === compareTo)
    },

    prepareParemetersForQuery() {
      this.selectedItem.balanceCase = this.selectedGroupName === 'all'
                                    ? null
                                    : this.selectedGroupName

      this.selectedItem.isSuspended = this.helper.includeSuspended ? null : this.helper.suspendedOnly;
    },

    preparePrametersForPrint() {
      const selectedStudent = this.filterDataBaseOnId(this.students, this.selectedItem.studentId);
      const allStudents = this.filterDataBaseOnId(reportParameters, 'allStudents');
      this.selectedItem.studentName = this.selectedItem.studentId
                                    ? this.isRight ? selectedStudent.arabicName : selectedStudent.englishName
                                    : this.isRight ? allStudents.arabicName : allStudents.englishName;

      const selectedGuardian = this.filterDataBaseOnId(this.guardians, this.selectedItem.guardianId);
      const allGuardians = this.filterDataBaseOnId(reportParameters, 'allGuardians');
      this.selectedItem.guardianName = this.selectedItem.guardianId
                                     ? this.isRight ? selectedGuardian.arabicName : selectedGuardian.englishName
                                     : this.isRight ? allGuardians.arabicName : allGuardians.englishName;

      const selectedStage = this.filterDataBaseOnId(this.stages, this.selectedItem.stageId);
      const allStages = this.filterDataBaseOnId(reportParameters, 'allStages');
      this.selectedItem.stageName = this.selectedItem.stageId
                                  ? this.isRight ? selectedStage.arabicName : selectedStage.englishName
                                  : this.isRight ? allStages.arabicName : allStages.englishName;

      const selectedRows = this.filterDataBaseOnId(this.rows, this.selectedItem.rowId);
      const allRows = this.filterDataBaseOnId(reportParameters, 'allRows');
      this.selectedItem.rowName = this.selectedItem.rowId
                                ? this.isRight ? selectedRows.arabicName : selectedRows.englishName
                                : this.isRight ? allRows.arabicName : allRows.englishName;

      const selectedClass = this.filterDataBaseOnId(this.classrooms, this.selectedItem.classroomId);
      const allClasses = this.filterDataBaseOnId(reportParameters, 'allClassrooms');
      this.selectedItem.classroomName = this.selectedItem.classroomId
                                      ? this.isRight ? selectedClass.arabicName : selectedClass.englishName
                                      : this.isRight ? allClasses.arabicName : allClasses.englishName;

      this.selectedItem.balanceDiscription = this.selectedGroupName === 'all'
                                           ? this.$t('allValuesForPreviousBalance')
                                           : `${this.$t(this.selectedItem.balanceCase)} ${this.selectedItem.balanceValue}`;

      const selectedUser = this.filterDataBaseOnId(this.users, this.selectedItem.userId);
      const allUsers = this.filterDataBaseOnId(reportParameters, 'allUsers');
      this.selectedItem.voucherMakerName = this.selectedItem.userId
                                         ? this.isRight ? selectedUser.arabicName : selectedUser.englishName
                                         : this.isRight ? allUsers.arabicName : allUsers.englishName;

      if (this.type.route === 'collectionVouchers-report' || this.type.route === 'paymentVouchers-report') {
        const safePayment = this.filterDataBaseOnId(this.safes, this.selectedItem.paymentId);
        const selectedPayment = this.filterDataBaseOnId(this.payments, this.selectedItem.payMethodId);
        const allPayments = this.filterDataBaseOnId(reportParameters, 'allPaymentMethods');
        this.selectedItem.paymentMethodName = this.helper.paymentMethod
                                            ? this.helper.paymentMethod === 'cash'
                                              ? (this.isRight ? safePayment.arabicName : safePayment.englishName)
                                              : (this.isRight ? selectedPayment.arabicName : selectedPayment.englishName)
                                            : this.isRight ? allPayments.arabicName : allPayments.englishName;
      }

       // for taxes
       switch (this.helper.taxableStatus) {
        case 'taxable':
          this.selectedItem.isTaxable = true
          this.selectedItem.taxesStatus = this.isRight
                                        ? this.filterDataBaseOnId(this.taxesStatus, 'taxable').arabicName
                                        : this.filterDataBaseOnId(this.taxesStatus, 'taxable').englishName
          break;

        case 'nonTaxable':
          this.selectedItem.isTaxable = false
          this.selectedItem.taxesStatus = this.isRight
                                        ? this.filterDataBaseOnId(this.taxesStatus, 'nonTaxable').arabicName
                                        : this.filterDataBaseOnId(this.taxesStatus, 'nonTaxable').englishName
          break;

        default:
          this.selectedItem.isTaxable = null
          this.selectedItem.taxesStatus = this.$t('all')
          break;
      }

      if (this.helper.paymentMethod) {
        if (this.helper.paymentMethod === 'cash') {
          const selectedSafe = this.filterDataBaseOnId(this.safes, this.selectedItem.paymentId);
          const allSafes = this.filterDataBaseOnId(reportParameters, 'allSafes');
          this.selectedItem.paymentMethodId = 1;
          this.selectedItem.paymentName = this.selectedItem.paymentId
                                        ? this.isRight ? selectedSafe.arabicName : selectedSafe.englishName
                                        : this.isRight ? allSafes.arabicName : allSafes.englishName
        } else {
          const selectedBank = this.filterDataBaseOnId(this.banks, this.selectedItem.paymentId);
          const allBanks = this.filterDataBaseOnId(reportParameters, 'allBanks');
          this.selectedItem.paymentMethodId = 2
          this.selectedItem.paymentName = this.selectedItem.paymentId
                                        ? this.isRight ? selectedBank.arabicName : selectedBank.englishName
                                        : this.isRight ? allBanks.arabicName : allBanks.englishName
        }
      } else {
        this.selectedItem.paymentMethodId = null
        this.selectedItem.paymentName = this.isRight
                                      ? this.filterDataBaseOnId(reportParameters, 'allBanksAndSafes').arabicName
                                      : this.filterDataBaseOnId(reportParameters, 'allBanksAndSafes').englishName;
      }
    },

    print(repType) {
      this.checkDate();
      if (!this.checkForFiscalYearPerm(this.selectedItem.fromDate, this.selectedItem.toDate)) return;
      this.prepareParemetersForQuery();
      this.preparePrametersForPrint();
      const reportName = this.getReportNameBasedOnType(repType);
      this.printReport(reportName, this.selectedItem);
    },
  },
};
</script>

<style>
.gb-package {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: 3px;
}

.prev-balance label {
  font-size: 14px;
}
b-button-group {
  border-radius: 6px;
}

.gb-btn-child {
  border: 1px solid gainsboro;
}

.prev-balance .btnActive {
  background-color: rgba(115, 103, 240, 0.12)
}
</style>
